import ReactDatePicker, { DatePickerProps } from "react-datepicker";

import { SvgImage } from "components/shared";

import "react-datepicker/dist/react-datepicker.css";

type CustomDatePickerProps = DatePickerProps;

export const DatePicker = ({ ...props }: CustomDatePickerProps) => {
  return (
    <ReactDatePicker
      {...props}
      className="bg-inputbg border w-[150px] mr-3 text-center rounded-4 border-solid border-grey  pl-10 focus:outline-none"
      icon={<SvgImage name="CalendarIcon" />}
      showIcon
    />
  );
};
