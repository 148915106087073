import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ListVehicles, LoadVehicle, SearchBar } from "components/app";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { Pagination } from "components/shared";

import { useDealership } from "context/DealershipContext";
import { SearchContext } from "context/SearchContext";

import { FLOW_TYPE } from "utils";

import { IVehicleDetail } from "types";

import { VehicleService } from "api/client";

export const SearchList = () => {
  const navigate = useNavigate();
  const { showAllDealerships, dealershipID } = useDealership();
  const [currentPage, setCurrentPage] = useState(1);
  const { searchTxt, setSearchTxt } = useContext(SearchContext);

  useEffect(() => {
    if (searchTxt) {
      setCurrentPage(1);
    }
  }, [searchTxt]);

  const { data, isRefetching, isInitialLoading, refetch } = useQuery(
    [
      `fetch_search_global_search=${searchTxt}&page=${currentPage}`,
      searchTxt,
      currentPage,
      showAllDealerships ? dealershipID : undefined,
    ],
    VehicleService.fetchGlobalSearch,
    {
      enabled: !!searchTxt,
    }
  );

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchTxt(e.target.value);
  };

  const onSelect = (id: string) => {
    const vehicle = data?.data?.find(
      (vehicle: IVehicleDetail) => vehicle.contract_id === id
    );

    navigate(`/search/summary/${id}`, {
      state: { vehicle },
    });
  };

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const renderList = () => {
    if (isInitialLoading) {
      return <LoadVehicle />;
    }
    if (data?.data?.length === 0 || !searchTxt) {
      return (
        <div className="dashboard__content--empty">
          {searchTxt ? (
            <>
              <div className="text-xl text-txt font-semibold mb-5">
                No Results Found
              </div>
              <p>
                Looks like we couldn't find anything matching your search.
                <br />
                Let's try something different!
              </p>
            </>
          ) : (
            "No search string"
          )}
        </div>
      );
    }
    return (
      <ListVehicles
        vehicles={data?.data || []}
        onSelect={onSelect}
        flow={FLOW_TYPE.RETAIL_DEALERSHIP}
        searchText={searchTxt}
        isGlobalSearch
        refetch={refetch}
      />
    );
  };

  return (
    <div className="flex flex-col max-w-1300 w-full">
      <div className="my-3">
        <div className="title my-3">Search Results</div>
        <Pagination
          currentPage={currentPage}
          pageLimit={data?.meta?.per_page || 0}
          total={data?.meta?.total || 0}
          onNextPage={handleFetchNext}
          onPrevPage={handleFetchPrev}
          onIndexedPage={handleFetchSelected}
        />
      </div>
      {isRefetching && <LoadingSpinner />}
      <div className="dashboard__content">{renderList()}</div>
      <div className="pt-5 pb-10">
        <Pagination
          currentPage={currentPage}
          pageLimit={data?.meta?.per_page || 0}
          total={data?.meta?.total || 0}
          onNextPage={handleFetchNext}
          onPrevPage={handleFetchPrev}
          onIndexedPage={handleFetchSelected}
        />
      </div>
    </div>
  );
};
