import { ModalConfirm } from "components/app";
import { Modal } from "components/shared";

import { PPSRCheckInfo } from "types";

type PPSRRefreshWarningProps = {
  isOpen: boolean;
  onOk: () => void;
  onClose: () => void;
  ppsrInfo?: PPSRCheckInfo;
  isLoading: boolean;
};

export const PPSRRefreshWarning = ({
  isOpen,
  onClose,
  onOk,
  ppsrInfo,
  isLoading,
}: PPSRRefreshWarningProps) => {
  return (
    <Modal open={isOpen} size="auto" closeModal={onClose} isCenter>
      <div className="w-[310px] flex justify-center">
        <ModalConfirm
          title="PPSR Already Requested"
          description={`A PPSR check for this vehicle was last requested on the ${ppsrInfo?.created_at} and is still within the 14-days refresh period.`}
          cancelButtonLabel="CANCEL"
          confirmButtonLabel="New Check"
          onCancel={onClose}
          onConfirm={onOk}
          isLoading={isLoading}
          secondaryDescription="Refresh the record for updates or continue to request a new check"
          isFullWidth
        />
      </div>
    </Modal>
  );
};
