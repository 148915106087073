import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Button, FieldText, Modal, TextError } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { PPSRCheckInfo } from "types";

import { useSearchByVinOrContract } from "hooks/useSearchByVin";

import { PPSRRefreshWarning } from "./PPSRRefreshWarning";

type PPSRListSearchByVinProps = {
  onRefetch: () => void;
};

export const PPSRListSearchByVin = ({
  onRefetch,
}: PPSRListSearchByVinProps) => {
  const [data, setData] = useState<PPSRCheckInfo | undefined>(undefined);

  const [isOpen, setIsOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const { hasPermission } = useContext(AuthContext);

  const onClose = () => {
    setIsOpen(false);
    onRefetch();
  };

  const onForceSearch = (info: PPSRCheckInfo) => {
    setIsWarningOpen(true);
    setData(info);
  };

  const { isLoadingByVin, searchByVin } = useSearchByVinOrContract({
    onRefetch: onClose,
    onForceSearch,
    onCloseMainPrompt: () => {
      setIsOpen(false);
    },
    onCloseWarning: () => {
      setIsWarningOpen(false);
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vin: "",
    },
  });

  return (
    <>
      <Button type="button" onClick={() => setIsOpen(true)} className="px-5">
        <span className="wholesale__tool__title">New PPSR Check</span>
      </Button>
      <Modal
        open={isOpen}
        size="auto"
        title="Search by VIN"
        closeModal={() => setIsOpen(false)}
      >
        <div className="w-[400px]">
          <Controller
            render={({ field: { value, onChange } }) => (
              <FieldText
                title="VIN*"
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Enter your VIN"
              />
            )}
            control={control}
            name="vin"
            rules={{
              required: "VIN is required",
              validate: (value) =>
                value.length === 17 || "VIN must be exactly 17 characters",
            }}
          />
          {!!errors?.vin && <TextError error={errors?.vin?.message || ""} />}

          <div className="vin-rego__footer">
            <Button
              type="submit"
              onClick={handleSubmit((data) =>
                searchByVin({
                  vin: data.vin,
                  refetch: true,
                  forceSearch: false,
                })
              )}
              isLoading={isLoadingByVin}
              disabled={
                !hasPermission(
                  PermissionCategory.PPSR_REQUEST,
                  PermissionAction.CREATE
                )
              }
            >
              <span className="vin-rego__footer__title">Search</span>
            </Button>
          </div>
        </div>
      </Modal>
      <PPSRRefreshWarning
        ppsrInfo={data}
        isOpen={isWarningOpen}
        onClose={() => setIsWarningOpen(false)}
        onOk={() => {
          searchByVin({
            refetch: true,
            vin: data?.vin || "",
            forceSearch: true,
          });
        }}
        isLoading={isLoadingByVin}
      />
    </>
  );
};
