import { DatePicker } from "components/shared/DatePicker";

type FilterDateRangeProps = {
  startDate: Date | null;
  endDate: Date | null;
  onChangeStartDate: (value: Date | null) => void;
  onChangeEndDate: (value: Date | null) => void;
};

export const FilterDateRange = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
}: FilterDateRangeProps) => {
  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={onChangeStartDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
        showIcon
      />
      <DatePicker
        selected={endDate}
        onChange={onChangeEndDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate || undefined}
        placeholderText="End Date"
      />
    </>
  );
};
