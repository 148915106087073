import { useContext } from "react";

import { BuyingCard } from "pages/MotorMarket/Buying/BuyingCard";

import { CardTenderVehicle, CardVehicle } from "components/app";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  BUYNOW,
  FLOW_TYPE,
  hasEventEnded,
  MOTOR_MARKET_AUCTION,
  TENDER_FLOW_TYPE,
  TENDERED,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleDetail } from "types";
import "./styles.scss";
import { IMotorMarketBuyer } from "types/IMotorMarket";

type ListVehiclesProps = {
  vehicles: IVehicleDetail[];
  onSelect: (
    id: string,
    isExternal: boolean,
    requestId?: string,
    hasSeller?: boolean
  ) => void;
  flow?: FLOW_TYPE;
  searchText?: string;
  isGlobalSearch?: boolean;
  refetch?: () => void;
};

export const ListVehicles = ({
  vehicles,
  onSelect,
  flow,
  searchText,
  isGlobalSearch,
  refetch,
}: ListVehiclesProps) => {
  const { isDealershipSelectorVisible } = useDealership();
  const { hasPermission } = useContext(AuthContext);

  const showValuedByMP = hasPermission(
    PermissionCategory.MOTORINTEL_VALUATIONS,
    PermissionAction.VIEW
  );

  const canViewDirectOffer = hasPermission(
    PermissionCategory.DIRECT_OFFERS,
    PermissionAction.VIEW
  );

  const canViewMMBuying = hasPermission(
    PermissionCategory.MOTOR_MARKET_BUYING,
    PermissionAction.VIEW
  );

  const canTenderView = hasPermission(
    PermissionCategory.TENDER_BUYING,
    PermissionAction.VIEW
  );

  const canTenderBid = hasPermission(
    PermissionCategory.TENDER_BUYING,
    PermissionAction.BID
  );

  return (
    <div className="list">
      {vehicles.map((vehicle: IVehicleDetail) => {
        const isContractOwner = vehicle.is_contract_owner;
        const status = vehicle?.status?.name;
        if (!isContractOwner && status === MOTOR_MARKET_AUCTION.key) {
          return (
            <BuyingCard
              auction={vehicle as unknown as IMotorMarketBuyer}
              onClick={() =>
                onSelect(
                  vehicle.contract_id,
                  !vehicle.owner,
                  vehicle.request_id,
                  vehicle.seller
                )
              }
              refetch={() => refetch?.()}
              status={vehicle.auction_vehicle?.status?.name || status}
              canView={canViewMMBuying}
              key={vehicle.contract_id}
              isGlobalSearch={isGlobalSearch}
              searchText={searchText}
            />
          );
        }

        if (!isContractOwner && status === TENDERED.key) {
          return (
            <CardTenderVehicle
              key={vehicle.contract_id}
              data={vehicle}
              from={TENDER_FLOW_TYPE.BUYING}
              tenderStatus={status}
              tenderId={vehicle.tender_vehicle?.id || ""}
              tenderDealershipId={""}
              onRefresh={() => {
                refetch?.();
              }}
              editedBid={{
                amount: vehicle.tender_vehicle?.bid?.latest_bid || 0,
                tenderVehicleId: vehicle.tender_vehicle?.id || "",
              }}
              onClick={() =>
                onSelect(
                  vehicle.contract_id,
                  !vehicle.owner,
                  vehicle.request_id,
                  vehicle.seller
                )
              }
              canView={canTenderView}
              canBid={canTenderBid}
              isGlobalSearch={isGlobalSearch}
              isEnded={hasEventEnded(
                vehicle.tender_vehicle?.ends_at || "",
                "yyyy-MM-dd hh:mm a"
              )}
              searchText={searchText}
            />
          );
        }

        return (
          <CardVehicle
            key={vehicle.contract_id}
            data={vehicle}
            onClick={() =>
              onSelect(
                vehicle.contract_id,
                !vehicle.owner,
                vehicle.request_id,
                vehicle.seller
              )
            }
            isExternal={
              vehicle?.seller?.id !== vehicle.dealership?.id ||
              !!vehicle?.requester_dealership
            }
            showValuedByMP={showValuedByMP}
            flow={flow}
            isDealershipSelectorVisible={isDealershipSelectorVisible}
            disabled={vehicle.status.name === BUYNOW.key && !canViewDirectOffer}
            searchText={searchText}
            isValuationRequest={vehicle?.is_valuation_request}
            isGlobalSearch={isGlobalSearch}
          />
        );
      })}
    </div>
  );
};
