import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";

import { Card } from "components/Card";
import { ButtonLink } from "components/shared";
import { HighlightText } from "components/shared/HighlightText";
import { Timer } from "components/Timer";
import { Tooltip } from "components/Tooltip";

import {
  MOTOR_MARKET_BUYER_BID,
  MOTOR_MARKET_ENDED,
  MOTOR_MARKET_LOST,
  MOTOR_MARKET_PENDING,
  MOTOR_MARKET_WITHDRAWN_BUYER,
  MOTOR_MARKET_WON,
  createEllipsisText,
  formatDateToJsDate,
  formatNumber,
  showErrorToast,
  hasEventEnded,
  PENDING,
} from "utils";

import { IVehicleDetail } from "types";
import { IMotorMarketBuyer } from "types/IMotorMarket";

import { ReactComponent as MarketStarIconFilled } from "assets/images/market-star-filled.svg";
import { ReactComponent as MarketStarIconOutlined } from "assets/images/market-star-outline.svg";
import { ReactComponent as PinIcon } from "assets/images/pin.svg";

import { MotorMarketService } from "api/client";

type BuyingCardProps = {
  auction: IMotorMarketBuyer | IVehicleDetail;
  status: string;
  onClick: (auction: IMotorMarketBuyer) => void;
  refetch: () => void;
  canView?: boolean;
  isGlobalSearch?: boolean;
  searchText?: string;
};

export const BuyingCard = ({
  auction,
  status,
  onClick,
  refetch,
  canView = true,
  isGlobalSearch,
  searchText,
}: BuyingCardProps) => {
  const { mutate: addToWatchList, isLoading: addingToWatchlist } = useMutation(
    (auctionVehicleID: string) =>
      MotorMarketService.addToWatchlist(auctionVehicleID),
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        showErrorToast("Failed To Add");
      },
    }
  );

  const { mutate: removeFromWatchlist, isLoading: removingFromWatchlist } =
    useMutation(
      (auctionWatchlistID: string) =>
        MotorMarketService.removeFromWatchlist(auctionWatchlistID),
      {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          showErrorToast("Failed To Remove");
        },
      }
    );

  const isWatched =
    "is_watched" in auction
      ? auction?.is_watched
      : !!auction.auction_vehicle?.auction_watchlist_id;

  const hasWidthdrawn =
    "has_withdrawn" in auction &&
    (auction as IMotorMarketBuyer)?.has_withdrawn &&
    auction.status.name !== MOTOR_MARKET_ENDED.key;

  const endsAt =
    "ends_at" in auction ? auction?.ends_at : auction?.auction_vehicle?.ends_at;

  const showCardStatusLabels = isGlobalSearch
    ? [PENDING.key].includes(status)
    : [
        MOTOR_MARKET_PENDING.key,
        MOTOR_MARKET_LOST.key,
        MOTOR_MARKET_WON.key,
        MOTOR_MARKET_BUYER_BID.key,
      ].includes(status);

  return (
    <Card
      key={auction?.contract_id}
      coverSource={auction?.images?.[0]?.url}
      hasWithdrawn={hasWidthdrawn}
      status={
        showCardStatusLabels
          ? {
              status: hasWidthdrawn ? MOTOR_MARKET_WITHDRAWN_BUYER.key : status,
              amount:
                "bid_summary" in auction
                  ? auction?.bid_summary?.latest_bid
                  : auction.auction_vehicle?.bid_summary?.latest_bid,
              label: isGlobalSearch
                ? status
                : hasWidthdrawn
                ? MOTOR_MARKET_WITHDRAWN_BUYER.label
                : [
                    MOTOR_MARKET_PENDING.key,
                    MOTOR_MARKET_BUYER_BID.key,
                  ].includes(status)
                ? MOTOR_MARKET_PENDING.label
                : auction.status.name === MOTOR_MARKET_ENDED.key &&
                  (auction as IMotorMarketBuyer).has_withdrawn
                ? MOTOR_MARKET_LOST.label
                : auction.status.label,
            }
          : undefined
      }
      content={[
        <div
          className="flex flex-col gap-2"
          key={
            "auction_vehicle_id" in auction
              ? auction?.auction_vehicle_id
              : auction?.contract_id
          }
        >
          <p className="text-base">
            <span className="font-semibold">
              <Tooltip
                className="line-clamp-2 truncate whitespace-normal"
                tooltip={auction?.vehicle?.name}
              >
                <HighlightText
                  text={auction?.vehicle?.name}
                  highlight={searchText}
                />
              </Tooltip>
            </span>
          </p>
          <div className="flex flex-row items-start space-x-8">
            <div className="flex flex-col gap-2.5 max-w-sm">
              <div className="flex items-center">
                <p className="text-sm text-label ">
                  {createEllipsisText(
                    Object.values(auction?.vehicle?.specification)
                      .filter(
                        (value) =>
                          !!value &&
                          !auction.vehicle.name.includes(
                            value as unknown as string
                          )
                      )
                      .join(" | "),
                    80
                  )}
                </p>
              </div>
              <p className="text-sm">
                {"KM: "}
                <span className="font-semibold">
                  {auction?.vehicle?.odometer
                    ? formatNumber(auction?.vehicle?.odometer)
                    : "-"}
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-2.5">
              <div className="flex items-center">
                <PinIcon />
                <p className="text-sm text-label mx-1">
                  {"contact" in auction
                    ? auction?.contact?.location
                    : auction?.auction_vehicle?.contact?.location}
                </p>
              </div>
              <p className="text-sm">
                {"Seller: "}
                <span className="font-semibold">
                  {"contact" in auction
                    ? auction?.contact?.dealership?.name
                    : auction?.source?.value}
                </span>
              </p>
              <p className="text-sm text-label ">
                {"ends_at" in auction
                  ? auction?.ends_at
                  : auction?.auction_vehicle?.ends_at}
              </p>
            </div>
          </div>
        </div>,
      ]}
      liveTimer={
        <div className={classNames({ "opacity-50": hasWidthdrawn })}>
          <Timer
            targetDate={formatDateToJsDate(
              "ends_at" in auction
                ? auction?.ends_at
                : auction?.auction_vehicle?.ends_at || ""
            )}
          />
        </div>
      }
      actions={[
        !hasEventEnded(endsAt || "") && canView && (
          <ButtonLink
            key={auction?.contract_id}
            disabled={addingToWatchlist || removingFromWatchlist}
            onClick={() =>
              isWatched
                ? removeFromWatchlist(
                    "auction_watchlist_id" in auction
                      ? auction?.auction_watchlist_id
                      : auction?.auction_vehicle?.auction_watchlist_id || ""
                  )
                : addToWatchList(
                    "auction_vehicle_id" in auction
                      ? auction?.auction_vehicle_id
                      : auction?.auction_vehicle?.id || ""
                  )
            }
          >
            <div className="flex items-center gap-2 font-medium">
              {isWatched ? (
                <MarketStarIconFilled />
              ) : (
                <MarketStarIconOutlined />
              )}
              {isWatched ? "Remove from watchlist" : "Add to watchlist"}
            </div>
          </ButtonLink>
        ),
      ]}
      onClick={
        hasWidthdrawn ? undefined : () => onClick(auction as IMotorMarketBuyer)
      }
    />
  );
};
