import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import "../styles.scss";
import { modalSendLinkTestID } from "components/app/dataTestIDs";
import {
  TextError,
  FieldText,
  Button,
  SvgImage,
  FieldTextarea,
} from "components/shared";
import { TermsAndConditions } from "components/TermsAndConditions";

import { INSPECTION_TYPE } from "utils";
import { pattern } from "utils/validation";

import { CustomerService, InspectionService } from "api/client";

interface SendLinkFormType {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_name?: string;
  comment?: string;
}

export interface ModalSendLinkProps {
  dataTestID?: string;
  inspectionType: INSPECTION_TYPE;
}

export const ModalSendLink = ({
  dataTestID,
  inspectionType,
}: ModalSendLinkProps) => {
  const [showSendLinkModal, setShowSendLinkModal] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company_name: "",
      comment: "",
    },
  });

  const emailIsValid = pattern.email.test(watch("email"));

  const { mutate, isLoading } = useMutation(
    (data: any) => CustomerService.create(data),
    {
      onSuccess: (res) => {
        handleSendLink((res as any).data.id);
      },
      onError: () => {
        toast.error("Failed to Send!", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );
  const { mutate: handleSendLink, isLoading: isSendingLink } = useMutation(
    (customerID: string) =>
      InspectionService.sendLink(customerID, {
        inspection_type: inspectionType ?? "",
      }),
    {
      onSuccess: () => {
        setShowSendLinkModal(true);
        toast.success("Link Sent Successfully!", {
          autoClose: 2000,
          theme: "colored",
        });
      },
      onError: () => {
        toast.error("Failed to Send!", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const onSendLink = async (data: SendLinkFormType) => {
    mutate(data);
  };

  return (
    <div className="send-link" data-testid={dataTestID || modalSendLinkTestID}>
      {!showSendLinkModal ? (
        <div className="link-send">
          <h3 className="link-send__title">Customer Information</h3>
          <div className="link-send__row">
            <div>
              <FieldText
                title="First Name*"
                type="text"
                {...register("first_name", { required: true })}
                placeholder="Enter their first name"
                isBgRequired
              />
              {errors.first_name && (
                <TextError error="First Name is required" />
              )}
            </div>

            <div>
              <FieldText
                title="Last Name*"
                type="text"
                {...register("last_name", { required: true })}
                placeholder="Enter their last name"
                isBgRequired
              />
              {errors.last_name && <TextError error="Last Name is required" />}
            </div>

            <div>
              <FieldText
                title="Email Address"
                type="text"
                {...register("email", {
                  pattern: pattern.email,
                })}
                placeholder="Enter their email address"
              />
              {errors.email && (
                <TextError
                  error={
                    getValues("email")
                      ? "Please enter the correct email"
                      : "Email Address is required"
                  }
                />
              )}
            </div>

            <div>
              <FieldText
                title="Mobile Number*"
                type="text"
                {...register("phone", {
                  validate: (value) =>
                    value || emailIsValid ? true : "Mobile Number is required",
                  pattern:
                    /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/,
                })}
                placeholder="Enter their mobile number"
                isBgRequired={!emailIsValid}
              />
              {errors.phone && (
                <TextError
                  error={
                    getValues("phone")
                      ? "Please enter the correct phone number"
                      : !emailIsValid
                      ? "Mobile Number is required"
                      : ""
                  }
                />
              )}
            </div>

            <FieldText
              title="Company Name"
              type="text"
              {...register("company_name")}
              placeholder="Enter their company name"
            />
            <FieldTextarea
              title="Comments"
              {...register("comment")}
              placeholder="Enter your comments here"
            />
          </div>
          <div className="mt-[38px] w-full align-middle">
            <TermsAndConditions />
            <div className="w-[200px] my-0 mx-auto">
              <Button
                type="submit"
                isLoading={isLoading || isSendingLink}
                onClick={handleSubmit(onSendLink)}
              >
                <span className="uppercase text-lighttxt text-sm font-bold">
                  Send Inspection Link
                </span>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="link-sent">
          <div className="link-sent__content">
            <SvgImage name="TickCircleIcon" />
            <h5 className="link-sent__title">Sent Link</h5>
            <p className="link-sent__desc">
              The vehicle inspection link has been successfully sent to the
              customer. Once they have completed and submitted it, you will be
              notified. Once submitted, their inspection will appear in your
              Valuation window tagged as Client Inspection.”
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
